<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" v-lazy='require("@/assets/img/about/contact/banner-bg.png")'>
      <img class="banner-center" v-lazy='require("@/assets/img/about/banner-center.png")'>
      <p class="banner-title">每一次沟通 都有温度</p>
    </div>
    <div id="detail">
      <div class="detail-info">
        <img class="detail-logo" v-lazy='require("@/assets/img/about/contact/logo.png")'>
        <p class="detail-title" style="margin-top: .21rem;">公司名称</p>
        <p class="detail-subTitle">南京麦豆健康科技有限公司</p>
        <p class="detail-title">地址</p>
        <p class="detail-subTitle">南京市雨花台区凤展路32号A2北栋7层</p>
        <p class="detail-title">电话</p>
        <p class="detail-subTitle">服务热线：400-133-5668</p>
        <p class="detail-subTitle" style="margin-top: .1rem;">经销合作：17372217350</p>
        <p class="detail-title">邮箱</p>
        <p class="detail-subTitle">support@medoht.com</p>
        <img class="detail-qrcode" v-lazy='require("@/assets/img/index/qrcode-wechat.png")'>
        <p class="detail-qrcode-desc">扫描二维码<br/>关注澜渟微信公众号</p>
        <p class="detail-qrcode-wetchat">微信号：WrightinHealth</p>
      </div>
      <div class="detail-map">
        <baidu-map class="baiduMap" :center="position" :zoom="16" :scroll-wheel-zoom="true"
                   ak="7sTPX5Pn0xrZpvpXZ6dKG99i8aiK4fG9">
          <bm-marker :position="position" :icon="markIcon">
            <bm-info-window :show="true">
              <p class="map-company">南京麦豆健康科技有限公司</p>
              <p class="map-address">南京市雨花台区凤展路32号A2北栋7层</p>
            </bm-info-window>
          </bm-marker>
        </baidu-map>
      </div>
    </div>
    <div id="fill-info" v-if="showFill">
      <div class="fill-title">
        <img :src='require("@/assets/img/about/contact/phone.png")' alt/>
        招商电话：173-7221-7350
      </div>
      <div class="fill-form">
        姓名：
        <input type="text" placeholder="您的称呼" v-model="fillName" maxlength="6">
      </div>
      <div class="fill-form">
        手机号：
        <input type="text" placeholder="您的手机号" v-model="fillPhone" maxlength="11">
      </div>
      <div class="fill-btn" :class="compute() ? '' : 'disabled'" @click="submitFill">提交信息</div>
      <img class="fill-close" @click="showFill = false" :src='require("@/assets/img/about/contact/close.png")' alt/>
    </div>
    <md-custom :show.sync="submitResult" id="submitResult" type="transparent">
      <div class="submit-result">
        <img @click="submitResult = false" class="close" :src='require("@/assets/img/about/contact/close.png")' alt/>
        <img class="icon" :src='require("@/assets/img/about/contact/submit-success.png")' alt/>
        <p class="title">提交成功</p>
        <p class="sub-title">提交信息后，我们将在 <span>3</span> 个工作日内跟您联系，请保持手机畅通</p>
      </div>
    </md-custom>
    <md-custom :show.sync="loading" id="load" type="transparent">
    </md-custom>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue';
import MdCustom from '../../components/custom/src/custom';

export default {
  data() {
    return {
      position: {
        lng: 118.74803126003286,
        lat: 31.943477091396716,
      },
      markIcon: {
        url: require('@/assets/img/about/contact/position.png'),
        size: {
          width: 1.8,
          height: 2.4,
        },
      },
      showFill: true,
      fillName: '',
      fillPhone: '',
      loading: false,
      submitResult: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    compute() {
      if (this.fillName === '') {
        return false;
      }
      if (this.fillPhone === '') {
        return false;
      }
      // 正则 1+[0-9]{10}
      if (!/^1[0-9]{10}$/.test(this.fillPhone)) {
        return false;
      }
      return true;
    },
    submitFill() {
      if (this.fillName === '') {
        return;
      }
      if (this.fillPhone === '') {
        return;
      }
      // 正则 1+[0-9]{10}
      if (!/^1[0-9]{10}$/.test(this.fillPhone)) {
        return;
      }

      this.loading = true;
      this.$axios.post(`${this.$request.live}/common/pc/fill-info`, {
        name: this.fillName,
        phone: this.fillPhone,
      }).then(res => {
        this.loading = false;
        this.submitResult = true;
        this.fillName = '';
        this.fillPhone = '';
      });
    },
  },
  components: {
    BaiduMap,
    BmMarker,
    BmInfoWindow,
    MdCustom,
  },
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 4.0rem;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  > .banner-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 6.43rem;
    height: 1.7rem;
  }

  > .banner-title {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 6.36rem;
    height: 1.64rem;
    text-align: center;
    line-height: 1.64rem;
    font-size: .44rem;
    font-weight: bold;
    color: #4a384a;
  }
}

#detail {
  position: relative;
  margin-top: .6rem;
  margin-bottom: 1.0rem;
  height: 7.21rem;

  > .detail-info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3.6rem;
    padding-left: .32rem;
    width: 3.5rem;

    > .detail-logo {
      width: 2.34rem;
      height: 1.24rem;
    }

    > .detail-title {
      margin-top: .44rem;
      line-height: .28rem;
      font-size: .2rem;
    }

    > .detail-subTitle {
      margin-top: .02rem;
      line-height: .22rem;
    }

    > .detail-qrcode {
      margin: .33rem auto 0 .74rem;
      width: 1.06rem;
      height: 1.06rem;
    }

    > .detail-qrcode-desc {
      margin-top: .02rem;
      width: 2.53rem;
      text-align: center;
      line-height: .17rem;
      font-size: .12rem;
      letter-spacing: .009rem;
      color: #999;
    }

    > .detail-qrcode-wetchat {
      margin-top: .07rem;
      width: 2.54rem;
      text-align: center;
      line-height: .22rem;
    }
  }

  > .detail-map {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 3.6rem;
    width: 8.5rem;

    > .baiduMap {
      height: 100%;

      .map-company {
        font-weight: bold;
        color: #dd5555;
      }

      .map-address {
        margin-top: .05rem;
        font-size: .14rem;
      }

      /deep/ .anchorBL {
        display: none;
      }
    }
  }
}

#fill-info {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0.9rem;
  background-color: #EAE0FA;
  z-index: 1;
  display: flex;
  align-items: center;

  > .fill-title {
    height: 100%;
    padding: 0 0.7rem 0 0.89rem;
    font-weight: 500;
    font-size: 0.22rem;
    color: #FFFFFF;
    line-height: 0.31rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #7F4DC9;
    border-top-right-radius: .45rem;
    border-bottom-right-radius: .45rem;

    > img {
      width: .58rem;
      height: .58rem;
      margin-right: 0.24rem;
    }
  }

  > .fill-form {
    padding-left: 1rem;
    font-size: 0.2rem;
    color: #333333;

    > input {
      width: 2rem;
      height: .4rem;
      border: none;
      outline: none;
      background: #fff;
      border-radius: .2rem;
      font-size: .2rem;
      line-height: 0.28rem;
      color: #333;
      text-align: center;

      &::placeholder {
        color: #999;
      }
    }
  }

  > .fill-btn {
    margin-left: 3.2rem;
    padding: 0.06rem 0.2rem;
    font-size: 0.2rem;
    color: #FFFFFF;
    line-height: 0.28rem;
    cursor: pointer;
    background: #7F4DC9;
    border-radius: 1rem;

    &:hover, &:active, &:focus {
      background: #553386;
    }

    &.disabled {
      background: #AA88DB;
    }
  }

  > .fill-close {
    margin-left: 1.16rem;
    width: 0.32rem;
    cursor: pointer;
  }
}

.submit-result {
  position: fixed;
  width: 5.6rem;
  height: 3.93rem;
  background: #FFFFFF;
  box-shadow: 0 0.02rem 0.12rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  // 居中
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .close {
    width: 0.24rem;
    height: 0.24rem;
    position: absolute;
    right: 0.24rem;
    top: 0.24rem;
    cursor: pointer;
  }

  > .icon {
    width: 0.92rem;
  }

  > .title {
    margin-top: 0.2rem;
    font-weight: 500;
    font-size: 0.32rem;
    color: #333333;
    line-height: 0.45rem;
  }

  > .sub-title {
    margin-top: 0.16rem;
    font-size: 0.14rem;
    color: #999999;
    line-height: 0.2rem;

    > span {
      font-size: inherit;
      line-height: inherit;
      color: #7F4DC9;
    }
  }
}
</style>
